import Cal, { getCalApi } from "@calcom/embed-react";
import {useEffect} from "react";
import './Calendar.css'

function Calendar(props) {
  useEffect(()=>{
    (async function () {
      const cal = await getCalApi();
      cal("ui", {"theme":"light","styles":{"branding":{"brandColor":"#03206A"}}});
    })();
  }, []);
  return (
    <div className="app-wrapper">
      <div className="left">
        {/*<img src="bottom_bg.png" />*/}
        <img src="revox_logo.svg" className="logo"/>

        <div className="hidden lg:block">
          <img src="header.svg" className="header"/>
          <img src="text.svg" className="text"/>
        </div>
      </div>
      <div className="bg-white cal-wrapper shadow-xl">
        <Cal calLink={props.link} style={{
          width:"100%",
          height:"100%",
          overflowY:"scroll",
          overflowX: 'hidden',
          scrollbarWidth: 'none',
          padding: 18
        }}/>
      </div>
    </div>
  );
};

export default Calendar
